body {
  align-items: center;
  color: #333;
  display: flex;
  font-family: 'Lucida Sans Unicode', 'Lucida Grande', sans-serif;
  height: 100%;
  height: 100vh;
  justify-content: center;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

ul {
  padding: 0;
}
